<template>
    <el-select v-model="code" size="small">
        <el-option value="" label="请选择" />
        <el-option v-for="area in areas" :value="area.code" :key="area.code" :label="area.name" />
    </el-select>
</template>
<script>
import Util from 'js/Util';

export default {
    name: 'EfArea',
    model: {
        prop: 'selected',
        event: 'change',
    },
    props: {
        selected: {
            type: String,
            default: '',
        },
        level: {
            type: Number,
            required: true,
        },
        parentCode: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            code: '',
            areas: [],
            url: {
                queryArea: '/system/area/list',
            },
        };
    },
    watch: {
        selected: {
            handler(n, o) {
                //假设：null与''等价
                if (n == null) {
                    n = '';
                }
                this.code = n;
            },
            immediate: true,
        },
        code: {
            handler(n, o) {
                this.$emit('change', n);
            },
            immediate: true,
        },
        parentCode: {
            handler(n, o) {
                this.queryArea();
            },
            immediate: true,
        },
    },
    mounted() {
        this.queryArea();
    },
    methods: {
        queryArea() {
            if (this.level == 1) {
                const params = { params: { level: this.level, parentCode: this.parentCode } };
                Util.queryTable(this, this.url.queryArea, params, (data) => {
                    this.areas = data.data;
                });
            } else if (Util.isEmpty(this.level) || Util.isEmpty(this.parentCode)) {
                this.areas = [];
                return;
            }
            const params = { params: { level: this.level, parentCode: this.parentCode } };
            Util.queryTable(this, this.url.queryArea, params, (data) => {
                this.areas = data.data;
            });
        },
    },
};
</script>
