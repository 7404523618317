<template>
    <div class="DailySettle" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="85px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构组名称">
                            <el-select
                                v-model="form.deptGroupCode"
                                @change="handleChangeGroup"
                                filterable
                                :loading="loadingDeptGroupFlag"
                            >
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="group in meta.groups"
                                    :value="group.code"
                                    :key="group.code"
                                    :label="group.name"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select v-model="form.deptCode" @change="queryRelatedStaff" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in meta.deptCodes"
                                    :label="dept.name"
                                    :key="dept.code"
                                    :value="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input placeholder="会员名称/手机号" v-model="form.creator" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建时间">
                            <el-date-picker v-model="form.createDate" type="date" value-format="yyyy-MM-dd" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="save" size="small" :disabled="form.tableData.length <= 0">保存</el-button>
            <el-button type="primary" size="small" @click="saveAndGive" :disabled="form.tableData.length <= 0"
                >保存且发卡</el-button
            >
            <el-button type="primary" @click="handleAdd" size="small" :disabled="!form.deptCode">添加</el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form :rules="rules" :model="form" ref="memberForm">
                <el-table
                    id="printMe"
                    border
                    stripe
                    style="width: 100%"
                    :data="form.tableData"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="520"
                >
                    <el-table-column label="序号" width="60" type="index" fixed="left" />
                    <el-table-column label="姓名" width="100" prop="code">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.names" size="mini" />
                        </template>
                    </el-table-column>
                    <el-table-column label="手机号" width="130" prop="deptGroupName">
                        <template slot-scope="scope">
                            <el-form-item :prop="'tableData.' + scope.$index + '.mobiles'" :rules="rules.mobiles">
                                <el-input v-model="scope.row.mobiles" size="mini" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="等级" width="120">
                        <template slot-scope="scope">
                            <el-select placeholder="等级" v-model="scope.row.levels" size="mini">
                                <el-option
                                    v-for="level in meta.memberLevels"
                                    :value="level.level"
                                    :key="level.level"
                                    :label="level.level + '(' + level.discount + '折)'"
                                />
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column label="性别" width="80">
                        <template slot-scope="scope">
                            <el-select placeholder="性别" v-model="scope.row.sexes" size="mini">
                                <el-option label="男" :value="0" />
                                <el-option label="女" :value="1" />
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column label="年龄" width="80" prop="operatorName">
                        <template slot-scope="scope">
                            <el-input v-model.number="scope.row.ages" size="mini" />
                        </template>
                    </el-table-column>
                    <el-table-column label="生日" width="180" prop="birthday">
                        <template slot-scope="scope">
                            <el-date-picker
                                v-model="scope.row.birthday"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="选择日期"
                                :picker-options="pickerOptions"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column label="身份证号码" width="180" prop="reviewStatus">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.idNumbers" size="mini" />
                        </template>
                    </el-table-column>
                    <el-table-column label="推荐人" width="120" prop="settleDate">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.developerCodes" size="mini" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="staff in meta.staffs"
                                    :value="staff.code"
                                    :key="staff.code"
                                    :label="staff.realName"
                                />
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column label="省份" width="120">
                        <template slot-scope="scope">
                            <EfArea :level="1" v-model="scope.row.provinces" />
                        </template>
                    </el-table-column>
                    <el-table-column label="地市" width="120" prop="createTime">
                        <template slot-scope="scope">
                            <EfArea :level="2" :parent-code="scope.row.provinces" v-model="scope.row.cites" />
                        </template>
                    </el-table-column>
                    <el-table-column label="县区" width="120" prop="createTime">
                        <template slot-scope="scope">
                            <EfArea :level="3" :parent-code="scope.row.cites" v-model="scope.row.districts" />
                        </template>
                    </el-table-column>
                    <el-table-column label="地址" width="200" prop="createTime">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.addresses" size="small" />
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" width="75" header-align="center">
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="danger"
                                @click="deleteRow(scope.$index, form.tableData, scope.row)"
                                >删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>
<script>
import Util from '../../../../js/Util';
import UrlUtils from '../../../../js/UrlUtils';
import EfArea from '../../../components/EfArea';
import { mobileValidateRule } from 'js/validate/ValidateCommonRule';
export default {
    name: 'MemberCreat',
    components: { EfArea },
    data() {
        return {
            form: {
                deptGroupCode: '',
                deptCode: '',
                startTime: '',
                endTime: '',
                reviewStatus: '',
                search: '',
                createDate: new Date(),
                creator: this.$store.state.session.name,
                page: 1,
                limit: 100,
                tableData: [],
            },
            pickerOptions: {
                disabledDate: (time) => {
                    //不能大于当前日期
                    return time.getTime() > Date.now();
                },
            },
            meta: {
                provinces: [],
                cites: [],
                districts: [],
                groups: [],
                levels: [],
                deptCodes: [],
                staffs: [],
                memberLevels: [],
            },
            loadingDeptGroupFlag: true,
            url: {
                queryGroups: '/system/deptGroup/list',
                queryDeptByGroup: '/system/dept/deptTree',
                queryRelatedStaff: '/system/staff/relatedStaff',
                save: '/member/createExtend',
                queryDeptGroupMemberLevel: '/member/level/queryDeptGroupMemberLevel',
            },
            areaData: {
                level: 1,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            codes: [],
            rules: {
                mobiles: mobileValidateRule(),
            },
        };
    },
    mounted() {
        const _this = this;
        UrlUtils.DeptGroup(_this, (rst) => {
            _this.meta.groups = rst || [];
            _this.form.deptGroupCode = _this.meta.groups[0].code;
            _this.handleChangeGroup(_this.form.deptGroupCode);
            _this.loadingDeptGroupFlag = false;
        });
    },
    methods: {
        queryRelatedStaff() {
            const _this = this;
            _this.form.tableData = [];
            const _params = { params: { deptCode: _this.form.deptCode } };
            Util.queryTable(_this, _this.url.queryRelatedStaff, _params, (data) => {
                if (_this.form.creator !== 'admin') {
                    _this.meta.staffs = data.data.filter((value) => {
                        return value.username != 'admin';
                    });
                } else {
                    _this.meta.staffs = data.data;
                }
            });
        },
        save() {
            const _this = this;
            let disable = false;
            let idNumberDisable = false;
            if (!this.form.tableData.length) {
                return;
            }
            if (!this.form.tableData.length > 20) {
                this.$message.error('单次批量新建不超过20条');
                return;
            }
            this.form.tableData.forEach((v, i) => {
                if (Util.isEmpty(v.names) || Util.isEmpty(v.mobiles)) {
                    disable = true;
                }
                if (v.idNumber && !window.ef.valid.memberIdNumber(v.idNumber, true)) {
                    idNumberDisable = true;
                }
            });
            if (disable) {
                this.$message.error('姓名和手机号都不能为空');
                return;
            }
            if (idNumberDisable) {
                this.$message.error('填写的身份证号长度或者格式错误,最后的X必须大写');
                return;
            }
            const dealEmpty = (data = '  ') => {
                return data;
            };
            this.$refs.memberForm.validate(async (valid) => {
                if (!valid) {
                    return false;
                }
                let _params = {};
                await (_params = {
                    names: _this.form.tableData.map((d) => d.names),
                    birthdays: _this.form.tableData.map((d) => d.birthday),
                    mobiles: _this.form.tableData.map((d) => d.mobiles),
                    sexes: _this.form.tableData.map((d) => dealEmpty(d.sexes)),
                    ages: _this.form.tableData.map((d) => dealEmpty(d.ages)),
                    idNumbers: _this.form.tableData.map((d) => d.idNumbers || ' '),
                    provinces: _this.form.tableData.map((d) => d.provinces || ' '),
                    cites: _this.form.tableData.map((d) => d.cites || ' '),
                    districts: _this.form.tableData.map((d) => d.districts || ' '),
                    addresses: _this.form.tableData.map((d) => d.addresses || ' '),
                    developerCodes: _this.form.tableData.map((d) => d.developerCodes || ' '),
                    regDps: _this.form.tableData.map((d) => d.regDps || ' '),
                    levels: _this.form.tableData.map((d) => dealEmpty(d.levels)),
                });
                UrlUtils.PostRemote(this, this.url.save, _params, null, (data) => {
                    this.$message.success('保存成功');
                    _this.goBackAndReload();
                });
            });
        },
        saveAndGive() {
            const _this = this;
            let disable = false;
            if (!this.form.tableData.length) {
                return;
            }
            if (!this.form.tableData.length > 20) {
                this.$message.error('单次批量新建不超过20条');
                return;
            }
            this.form.tableData.forEach((v, i) => {
                if (Util.isEmpty(v.names) || Util.isEmpty(v.mobiles)) {
                    disable = true;
                }
            });
            if (disable) {
                this.$message.error('姓名和手机号都不能为空');
                return;
            }
            const dealEmpty = (data = '') => {
                return data;
            };
            this.$refs.memberForm.validate(async (valid) => {
                if (!valid) {
                    return false;
                }
                const _params = {
                    names: _this.form.tableData.map((d) => d.names),
                    mobiles: _this.form.tableData.map((d) => d.mobiles),
                    birthdays: _this.form.tableData.map((d) => d.birthday),
                    sexes: _this.form.tableData.map((d) => dealEmpty(d.sexes)),
                    ages: _this.form.tableData.map((d) => dealEmpty(d.ages)),
                    idNumbers: _this.form.tableData.map((d) => d.idNumbers || ' '),
                    provinces: _this.form.tableData.map((d) => d.provinces || ' '),
                    cites: _this.form.tableData.map((d) => d.cites || ' '),
                    districts: _this.form.tableData.map((d) => d.districts || ' '),
                    addresses: _this.form.tableData.map((d) => d.addresses || ' '),
                    developerCodes: _this.form.tableData.map((d) => d.developerCodes || ' '),
                    regDps: _this.form.tableData.map((d) => d.regDps || ' '),
                    levels: _this.form.tableData.map((d) => dealEmpty(d.levels)),
                };
                UrlUtils.PostRemote(this, this.url.save, _params, null, () => {
                    this.$message.success('保存成功');
                    Util.nameJump(
                        this,
                        'menu.member.memberCardCreate.create',
                        ['会员管理', '会员卡管理', '新建会员管理'],
                        {
                            mobile: _this.form.tableData.map((d) => d.mobiles),
                            deptCode: this.form.deptCode,
                            deptGroupCode: this.form.deptGroupCode,
                        }
                    );
                });
            });
        },
        deleteRow(index, rows, row) {
            rows.splice(index, 1);
        },
        handleAdd() {
            const _this = this;
            if (_this.form.deptCode == '') {
                _this.$message.error('请选择机构');
                return;
            }
            let thisMemberLevel = '';
            if (_this.meta.memberLevels.length > 0) {
                thisMemberLevel = _this.meta.memberLevels[0].level;
            }
            _this.form.tableData.push({
                sexes: 0,
                levels: thisMemberLevel,
                names: '',
                mobiles: '',
                ages: '',
                birthday: null,
                idNumbers: '',
                provinces: '',
                cites: '',
                districts: '',
                addresses: '',
                developerCodes: '',
                regDps: _this.form.deptCode,
            });
        },
        handleChangeGroup(value) {
            const _this = this;
            this.form.deptCode = '';
            if (_this.meta.deptCodes[value]) {
                return _this.meta.deptCodes[value];
            }
            UrlUtils.QueryRemote(this, this.url.queryDeptByGroup + '?deptGroupCode=' + value, (rst) => {
                _this.meta.deptCodes = rst;
                _this.form.deptCode = rst[0].code;
                _this.queryRelatedStaff();
            });
            const _params = { params: { deptGroup: value } };
            Util.queryTable(_this, _this.url.queryDeptGroupMemberLevel, _params, (data) => {
                _this.meta.memberLevels = data.data;
            });
            this.form.tableData = [];
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
